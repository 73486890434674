/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { EdiInboundOrderDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class EdiInboundOrderDataAccessService {
	private api = inject(ApiService);

	// GET @ edi/inbound/orders/
	getAll(query: EntityQueryDto<EdiInboundOrderDto>) {
		return this.api.post<EdiInboundOrderDto[]>(`ediInboundOrders.getAll`, { body: { data: query } });
	}

	// GET @ edi/inbound/orders/:id
	getById(id: number, query: FindOptionsDto<EdiInboundOrderDto>) {
		return this.api.post<EdiInboundOrderDto>(`ediInboundOrders.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<EdiInboundOrderDto>) {
		return this.api.post<number>(`ediInboundOrders.count`, { body: { data: query } });
	}
}
